import axios from 'axios';
import { API_NAPNEGOCIOS, API_REPORTES } from 'config/api.config';
import UserService from 'config/UserService';
import { IPrice, IProduct, ITypeProduct } from 'interfaces/Product';
import { ITypeGarment } from 'interfaces/TypeGarment';

export const postProducts = async (data: IProduct, type: string) => {
  const res = await axios.post(`${API_REPORTES}/product/${type}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putProducts = async (data: IProduct, type: string, id: string) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/product/${type}/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const deleteProducts = async (id: string) => {
  const res = await axios.delete(`${API_NAPNEGOCIOS}/product/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const importProducts = async (data: Object, type: string) => {
  const res = await axios.post(`${API_REPORTES}/product/import/${type}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProducts = async () => {
  const res = await axios.get(`${API_REPORTES}/product/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProductsByCategory = async (category: string) => {
  const res = await axios.get(`${API_REPORTES}/category/products/${category}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IAssigmentProductsCategory {
  categoryIds: string[];
  freeIds: string[];
}
export const assignProductsCategory = async (category: string, data: IAssigmentProductsCategory) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/category/assign/${category}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getKardex = async (product: string) => {
  const res = await axios.get(`${API_REPORTES}/operation/${product}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IAssigmentProducts {
  aggregates: any[];
}
export const assigmentProducts = async (data: IAssigmentProducts, product: string) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/product/aggregates/${product}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
export const assigmentProductsBySede = async (data: IAssigmentProducts, product: string) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/item/aggregates/${product}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IUpdateStatus {
  product: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusProducts = async (data: IUpdateStatus[]) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/item/status/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IUpdateCategoryStatus {
  category: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusCategory = async (data: IUpdateCategoryStatus[]) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/item/status-category/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const disableAllProducts = async () => {
  const res = await axios.delete(`${API_NAPNEGOCIOS}/item/disabled-all/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getStatusProduct = async (id: string) => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/item/status/${id}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

/// TYPES

export const postProductType = async (data: ITypeProduct) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/category/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProductType = async () => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/category/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const deleteProductType = async (id: string) => {
  try {
    const res = await axios.delete(`${API_NAPNEGOCIOS}/category/${id}`, {
      headers: { Authorization: UserService.token() },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const putProductType = async (id: string, data: ITypeProduct) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/category/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// PRICES
export const getProductPrices = async (id: string) => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/price/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postProductPrices = async (id: string, data: IPrice) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/price/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putProductPrices = async (id: string, data: IPrice) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/price/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const deleteProductPrices = async (id: string) => {
  const res = await axios.delete(`${API_NAPNEGOCIOS}/price/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const updatePricesByUnit = async (data: any) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/product/update-prices`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const updatePricesLiquidation = async (data: any) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/product/update-liquidation/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// MOVIMIENTO DIARIO

export const getDailyMovement = async () => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/product/inventory/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IPostDailyMovement {
  products: {
    _id: string;
    output: number;
    stock: number;
    finalStock: number;
  }[];
  idsOrders: string[];
}
export const postDailyMovement = async (data: IPostDailyMovement) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/operation/massive/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getLengthDailyMovement = async () => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/item/count-inventory/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// By Sedes
export const getProductsBySede = async () => {
  const res = await axios.get(`${API_REPORTES}/item/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
// Productos para vender por sede
export const getSaleProductsBySede = async () => {
  const res = await axios.get(`${API_NAPNEGOCIOS}/item/sale/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putPricesBySede = async (data: any, id: string) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/item/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putStockAndPricesBySede = async (data: any) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/item/update-stock-prices/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProductsByCodeWarehouse = async (code: string) => {
  const res = await axios.get(`${API_REPORTES}/item/warehouse/${code}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getTypeGarment = async () => {
  const res = await axios.get<ITypeGarment[]>(`${API_NAPNEGOCIOS}/type-garment/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postTypeGarment = async (data: any) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/type-garment/${UserService.company()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putTypeGarment = async (id: string, data: any) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/type-garment/${id}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const deleteTypeGarment = async (id: string) => {
  const res = await axios.delete(`${API_NAPNEGOCIOS}/type-garment/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
